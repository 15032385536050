<!--Extended Table starts-->
<div class="row" *ngIf="displayPage">
  <div class="col-12">
    <div class="content-header">{{'Company Details' | translate}}</div>
  </div>
</div>
<section id="extended2" *ngIf="displayPage">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <button type="button" class="btn btn-warning mr-1 mb-1" [disabled]="isClientUser || isEmployeeUser" (click)="onNewCompany()">{{'Add A Company' | translate}}</button>
          <div class="form-group form-inline" style="display: inline-block;">
          <input id="searchData" class="form-control ml-2" type="text" 
          name="searchData" [(ngModel)]="searchDataKey" (ngModelChange)="searchData()" placeholder="{{'Search with Company Name' | translate}}" />
          </div>
        </div>
        <div class="card-content">
          <div class="card-body table-responsive">
            <!--<table class="table text-center m-0">-->
            <table id="companyDetails" class="table table-striped">
              <thead>
                <tr> 
                  <!-- <th>#</th> -->
                  <th>{{'Company Name' | translate}}</th>
                  <th>{{'Address' | translate}}</th>
                  <th>{{'Phone Number' | translate}}</th>
                  <th>{{'Email' | translate}}</th>
                  
                  <th  *ngIf="isAdminUser">{{'Actions' | translate}}</th>
                  
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let companyDetail of companyDetails">
                <tr>
                  <!-- <td>{{ companyDetail.pk_tb_company_details }}</td> -->
                  <td><a *ngIf="isAdminUser" href="javascript:;" class="success p-0" (click)="updateCompanyDetails(companyDetail)">{{ companyDetail.company_name }}</a></td>
                  <td><a *ngIf="isAdminUser" href="javascript:;" class="success p-0" (click)="updateCompanyDetails(companyDetail)">{{ companyDetail.address }}</a></td>
                  <td><a *ngIf="isAdminUser" href="javascript:;" class="success p-0" (click)="updateCompanyDetails(companyDetail)">{{ companyDetail.contact_number }}</a></td>
                  <td><a *ngIf="isAdminUser" href="javascript:;" class="success p-0" (click)="updateCompanyDetails(companyDetail)">{{ companyDetail.email }}</a></td>

                  <td class="text-truncate">
                    
                    <a  *ngIf="isAdminUser" href="javascript:;" class="success p-0">
                      <i class="ft-edit-2 font-medium-3 mr-2" (click)="updateCompanyDetails(companyDetail)">
                      </i>
                    </a>
                
                  
                    <a  *ngIf="isAdminUser" href="javascript:;" class="danger p-0">
                      <i class="ft-x font-medium-3" (click)="deleteCompanyDetails(companyDetail.pk_tb_company_details)"></i>
                    </a>
                  
                  </td>
                </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
