import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import UserDetails from 'app/UserDetails/UserDetails';
import { LoginPageComponent } from './login-page.component';

@Injectable()
export class LoginService {
    user: Observable<firebase.User>;
    userDetails : UserDetails[];
    fullName : String;

    constructor(public router: Router) {
       

    }

    
}
