import { Component , ViewChild, OnInit, ChangeDetectorRef} from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import CompanyDetails from 'app/companyDetails/companyDetails';
import { HttpCommonMethods } from 'app/httpCommonMethods/httpCommonMethods';
import Projects from 'app/project/Projects';
import { AuthService } from 'app/shared/auth/auth.service';
import UserDetails from 'app/UserDetails/UserDetails';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxSpinnerService } from "ngx-spinner";

import {TranslateService} from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { Injectable, ViewEncapsulation } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDetailsComponent } from 'app/tables/companyDetails/companyDetails.component';
import { DatePipe } from '@angular/common';
import Comments from 'app/comments/comments';
import { DomSanitizer } from '@angular/platform-browser';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';



@Injectable()
export class CustomDateAdapter {
  fromModel(value: any): NgbDateStruct
  {
   
     if (!value)
      return null
      else if(value == null)
        return null;
        else {
          if(value.length > 0 ){
            let parts=value.split('/');
           return {year:+parts[0],month:+parts[1],day:+parts[2]}
                 }
                 return value;
        }
  }

  toModel(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date?date.year+"/"+('0'+date.month).slice(-2)
           +"/"+('0'+date.day).slice(-2):null
  }
}

@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return null
     let parts=value.split('/');
     return {year:+parts[0],month:+parts[1],day:+parts[2]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string
  {
    //return date?date.year+"/"+('0'+date.month).slice(-2)+"/"+('0'+date.day).slice(-2):null
    return date?('0'+date.day).slice(-2)+"/"+('0'+date.month).slice(-2)+"/"+date.year:null
  }
}


@Component({
    selector: 'app-extended-table',
    templateUrl: './projectDetails.component.html',
    styleUrls: ['./projectDetails.component.scss'],
    providers: [{provide: NgbDateAdapter, useClass: CustomDateAdapter},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})

export class ProjectDetailsComponent {

    
    projectDetails: any[];
    tempProjectDetails: any[];
    userrole : string;
    isAdminUser : boolean;
    isAdminLiteUser : boolean;
    isClientUser : boolean;
    isEmployeeUser : boolean;
    searchDataKey : string;
    temp_fk_tb_company : string;
    displayPage: boolean;
    selectedProjectStatus: any;
    filter : boolean = false;
    reportFromDate : any;
    reportToDate : any;
    tempArr: string[] = [];
       
   // projectStatus : string;
    
    projectStatus = [
      // { id: 1, name: 'ADMIN' , disabled: true},
      { id: 1, name: 'Inplannen' },
      { id: 3, name: 'Gereed' },
      { id: 2, name: 'Onderhanden' },
      { id: 4, name: 'Gefactureerd' }
    ];

   

    constructor(private translate: TranslateService, private bnIdle: BnNgIdleService,private router: Router, private authService: AuthService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute, private httpCommonMethods : HttpCommonMethods , private cdr:ChangeDetectorRef) {
          if(localStorage.length > 0){
            this.displayPage = true;
            console.log('Inside Project Details !')
          this.bnIdle.startWatching(this.httpCommonMethods.sessionTimeOut).subscribe((res) => {
            if(res) { 
                alert('Session Exipered ! You have been Loggedout ! Please Login again to resume.');
                localStorage.clear();
                this.displayPage = false;
                this.bnIdle.resetTimer();
                this.bnIdle.stopTimer();
                this.router.navigate(['/pages/login']); 
                location.reload(); 
            }
          })
          
          this.userrole = localStorage.getItem('role');
          if(this.userrole === 'admin') {
            this.isAdminUser = true;
          } else if(this.userrole === 'admin-lite'){
              this.isAdminLiteUser = true;
          } else if(this.userrole === 'client') {
              this.isClientUser = true;
          } else {
            this.isEmployeeUser = true;
          }
            this.temp_fk_tb_company = localStorage.getItem('fk_tb_company_details');
           
            if(this.isClientUser){
              this.getProjectDetailsByPK(this.temp_fk_tb_company);
            } else if(this.isAdminLiteUser){
              this.getProjectDetailsForAdminLiteByPK(this.temp_fk_tb_company);
            } else if(this.isAdminUser) {
              this.getProjectDetails();
            } else {
              this.getProjectDetailsByStatus();
            }
          } else {
            this.displayPage = false;
            alert('Either are not authorized to view this page / You have been logged out !')
            this.router.navigate(['/pages/login']);
          }
      }

      ngOnInit(): void {
        // this.getEmpUsers();
     }

    onInit(){
     
    }

    onAddProject(){
      if(this.isAdminUser || this.isAdminLiteUser){
        this.router.navigate(['/forms/addProject'], { relativeTo: this.route.parent });
      } else {
        alert("You are not authorized to Add New Project !");
      }
    }

    getProjectDetails(){
      this.httpCommonMethods.getCombinedProjectDetails()
        .subscribe(data => {
          if(data != null){
            this.projectDetails = data; 
            for (let index = 0; index < this.projectDetails.length; index++) {
              let element = this.projectDetails[index];
              if(element['status'] == 'Open') {
                element['status'] = 'Inplannen';
              } else if(element['status'] == 'In-Progress') {
                element['status'] = 'Gereed';
              } else if(element['status'] == 'In-Review') {
                element['status'] = 'Onderhanden';
              } else if(element['status'] == 'Closed') {
                element['status'] = 'Gefactureerd';
              } 
            }
            this.tempProjectDetails = this.projectDetails;
            this.cdr.detectChanges(); 
          }
          else {
            //alert('Invalid User');
           alert('Error');
          }    
        })
    }

    
    getProjectDetailsByStatus(){
      this.httpCommonMethods.getCombinedProjectDetailsByStatus()
        .subscribe(data => {
          if(data != null){
            this.projectDetails = data; 
            for (let index = 0; index < this.projectDetails.length; index++) {
              let element = this.projectDetails[index];
              if(element['status'] == 'Open') {
                element['status'] = 'Inplannen';
              } else if(element['status'] == 'In-Progress') {
                element['status'] = 'Gereed';
              } else if(element['status'] == 'In-Review') {
                element['status'] = 'Onderhanden';
              } else if(element['status'] == 'Closed') {
                element['status'] = 'Gefactureerd';
              } 
            } 
            //this.tempProjectDetails = data; 
            this.tempProjectDetails = this.projectDetails; 
            this.cdr.detectChanges(); 
          }
          else {
            //alert('Invalid User');
           alert('Error');
          }    
        })
    }

    //getProjectDetailsForAdminLiteByPK
    getProjectDetailsForAdminLiteByPK(fk_tb_company){
      this.httpCommonMethods.getProjectDetailsForAdminLiteByPK(fk_tb_company)
        .subscribe(data => {
          if(data != null){
            this.projectDetails = data; 
            for (let index = 0; index < this.projectDetails.length; index++) {
              let element = this.projectDetails[index];
              if(element['status'] == 'Open') {
                element['status'] = 'Inplannen';
              } else if(element['status'] == 'In-Progress') {
                element['status'] = 'Gereed';
              } else if(element['status'] == 'In-Review') {
                element['status'] = 'Onderhanden';
              } else if(element['status'] == 'Closed') {
                element['status'] = 'Gefactureerd';
              } 
            } 
            //this.tempProjectDetails = data; 
            this.tempProjectDetails = this.projectDetails; 
            this.cdr.detectChanges();
          }
          else {
            //alert('Invalid User');
           alert('No Projects Available !');
          }    
        })
    }


    getProjectDetailsByPK(fk_tb_company){
      this.httpCommonMethods.getCombinedProjectDetailsByPK(fk_tb_company)
        .subscribe(data => {
          if(data != null){
            this.projectDetails = data; 
            for (let index = 0; index < this.projectDetails.length; index++) {
              let element = this.projectDetails[index];
              if(element['status'] == 'Open') {
                element['status'] = 'Inplannen';
              } else if(element['status'] == 'In-Progress') {
                element['status'] = 'Gereed';
              } else if(element['status'] == 'In-Review') {
                element['status'] = 'Onderhanden';
              } else if(element['status'] == 'Closed') {
                element['status'] = 'Gefactureerd';
              } 
            } 
           // this.tempProjectDetails = data;
           this.tempProjectDetails = this.projectDetails;
            this.cdr.detectChanges();
          }
          else {
            //alert('Invalid User');
           alert('Error');
          }    
        })
    }

    updateProjectDetails(projectInfo){
      let data = JSON.stringify(projectInfo);
      this.router.navigate(['/forms/updateProjectLayout'], { relativeTo: this.route.parent , queryParams: { data } });
    }

    deleteProjectDetailsByPK(projectPK){
      if (confirm('Are you sure you want to delete this Project?')) {
     
          if(this.isAdminUser){
            this.httpCommonMethods.deleteProject(projectPK)
            .subscribe(data => {
            if (data != null) {
              location.reload();  
              this.cdr.detectChanges();
            }
            else {
              alert('Error occured while updating details !');
            }
            });
          } else {
            alert("You are not authorized to delete the Project !");
          }
      }
    }

    public searchData() {
      // Declare variables
      var input, filter, table, tr, td, i, txtValue;
      input = this.searchDataKey;
      
      filter = input.toUpperCase();
      table = document.getElementById("projectDetails");
      tr = table.getElementsByTagName("tr");
    
      // // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }

    toggleFilter(){
      if(!this.filter){
        this.filter = true;
      } else {
        this.filter = false;
      }
    }

    searchProjectDataBasedOnFilter(){
      
        var fromDate = "";
        var fromDateArr : any;
        var toDate = "";
        var toDateArr : any;
        if(this.reportFromDate != null){
          if(this.reportFromDate.length > 0){
          let tempFromDate = this.reportFromDate.split('/');
          fromDate = tempFromDate[0] + "-" + tempFromDate[1] + "-" + tempFromDate[2];
          } else {
            let tempFromDateMonth;
            let tempFromDateDay;
            if(this.reportFromDate['month'] < 9){
              tempFromDateMonth = '0' + this.reportFromDate['month'];
            }
            else{
              tempFromDateMonth = this.reportFromDate['month'];
            }
            if(this.reportFromDate['day'] < 9){
              tempFromDateDay = '0' + this.reportFromDate['day'];
            }
            else{
              tempFromDateDay = this.reportFromDate['day'];
            }
            fromDate = this.reportFromDate['year'] + '-' +tempFromDateMonth + '-' + tempFromDateDay;
          }
        }
        if(this.reportToDate != null){
          if(this.reportToDate.length > 0){
          let tempToDate = this.reportToDate.split('/');
          toDate = tempToDate[0] + "-" + tempToDate[1] + "-" + tempToDate[2];
          }
          else {
            let tempToDateMonth;
            let tempToDateDay;
            if(this.reportToDate['month'] < 9){
              tempToDateMonth = '0' + this.reportToDate['month'];
            }
            else{
              tempToDateMonth = this.reportToDate['month'];
            }
            if(this.reportToDate['day'] < 9){
              tempToDateDay = '0' + this.reportToDate['day'];
            }
            else{
              tempToDateDay = this.reportToDate['day'];
            }
            toDate =  this.reportToDate['year'] + '-' +tempToDateMonth + '-' + tempToDateDay;
          }
        }
       let status = null;
        if(this.tempArr.length > 0){
          let tempString;
        for (let index = 0; index < this.tempArr.length; index++) {
          console.log('Here')
          console.log(this.tempArr[index])
          // { id: 3, name: 'Inplannen' },
          // { id: 2, name: 'Onderhanden' },
          // { id: 4, name: 'Gefactureerd' }
          if(this.tempArr[index] == 'Inplannen'){
            this.tempArr[index] = 'Open';
          } else if(this.tempArr[index] == 'Gereed'){
            this.tempArr[index] = 'In-Progress';
          } else if(this.tempArr[index] == 'Onderhanden'){
            this.tempArr[index] = 'In-Review';
          } else if(this.tempArr[index] == 'Gefactureerd'){
            this.tempArr[index] = 'Closed';
          } 
          if(index > 0){
            tempString =   tempString  + ',' +  "'" +  this.tempArr[index] +  "'";
          } else {
            tempString = "'" + this.tempArr[index] + "'";
          }
          
        }
        status = tempString;
        }
        console.log(status)
        
        this.httpCommonMethods.getProjectDataBasedOnFilter( status , fromDate, toDate)
          .subscribe(data => {
            if (data != null) {
              this.projectDetails = data;
              for (let index = 0; index < this.projectDetails.length; index++) {
                let element = this.projectDetails[index];
                if(element['status'] == 'Open') {
                  element['status'] = 'Inplannen';
                } else if(element['status'] == 'In-Progress') {
                  element['status'] = 'Gereed';
                } else if(element['status'] == 'In-Review') {
                  element['status'] = 'Onderhanden';
                } else if(element['status'] == 'Closed') {
                  element['status'] = 'Gefactureerd';
                } 
              } 
              this.cdr.detectChanges();
            }
            else {
              this.projectDetails = null;
              alert('Er zijn geen relevante projecten gevonden.');
            }
            this.cdr.detectChanges();
          });
      }

      clearFilter(){
        //this.projectStatus = null;
        this.reportFromDate = null;
        this.reportToDate = null;
        this.projectDetails = this.tempProjectDetails;
        this.tempArr = null;
        location.reload();  
        //this.cdr.detectChanges();
      }

      
      onCheckboxChange(e) {
       if (e.target.checked) {
          this.tempArr.push(e.target.value);
        } else {
          const index = this.tempArr.indexOf(e.target.value);
          if (index > -1) {
            this.tempArr.splice(index, 1);
          }
        }
      }
}