import { Component , ViewChild, OnInit, ChangeDetectorRef} from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpCommonMethods } from 'app/httpCommonMethods/httpCommonMethods';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import UserDetails from "../../UserDetails/UserDetails";
import {TranslateService} from '@ngx-translate/core';




@Component({
    selector: 'app-employeeDetails',
    templateUrl: './employeeDetails.component.html',
    styleUrls: ['./employeeDetails.component.scss'],
})

export class EmployeeDetailsComponent  implements OnInit {
    
    userDetails: UserDetails[] ;
    userDetailsInfo: UserDetails[] ;
    userrole : string;
    isAdminUser : boolean;
    isClientUser : boolean;
    isEmployeeUser : boolean;
    isAdminLiteUser : boolean;
    displayPage: boolean;
    searchDataKey : any;
     
    constructor(private translate: TranslateService, private router: Router, private authService: AuthService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute, private httpCommonMethods : HttpCommonMethods, private cdr:ChangeDetectorRef) {
          if(localStorage.length > 0){
            this.displayPage = true;
            console.log('Inside Employee Details !')
          this.userrole = localStorage.getItem('role');
         
          if(this.userrole === 'admin'){
            this.isAdminUser = true;
          } else if(this.userrole === 'admin-lite'){
            this.isAdminLiteUser = true;
          }  else if(this.userrole === 'client') {
            this.isClientUser = true;
          } else {
            this.isEmployeeUser = true;
          }
          if(this.isClientUser || this.isEmployeeUser || this.isAdminLiteUser){
         
            alert('You are not authorized to view this page !')
            this.router.navigate(['/tables/projectDetails']);
          } else{
            this.getEmpUsers();
          }
        } else {
         
          this.displayPage = false;
          alert('Either are not authorized to view this page / You have been logged out !')
          this.router.navigate(['/pages/login']);
        }
      }
    ngOnInit(): void {
       // this.getEmpUsers();
    }

    onInit(){
        this.httpCommonMethods.getEmpUsers()
        .subscribe(data => {
          if(data != null){
          }
          else {
            alert('Error');
          }
          
        })
    }

    onAddUser(){ 
      this.router.navigate(['/forms/addUserLayout'], { relativeTo: this.route.parent });
    }
    getEmpUsers(){
    this.httpCommonMethods.getEmpUsers()
        .subscribe(data => {
          if(data != null){
            this.userDetails = data;
            this.cdr.detectChanges();
          }
          else {
           alert('Error');
          }
        })
    }

    updateUserDetails(userInfo){
        let data = JSON.stringify(userInfo);
        this.router.navigate(['/forms/updateUserLayout'], { relativeTo: this.route.parent , queryParams: { data } });
    }
    
    deleteUserDetails(userPK){
      if (confirm('Are you sure you want to delete this User?')) {
     
          this.httpCommonMethods.deleteUser(userPK)
          .subscribe(data => {
            if (data != null) {
              location.reload();  
            }
            else {
              alert('Error occured while updating details !');
            }
          });
      }
    }

    public searchData() {
      // Declare variables
      var input, filter, table, tr, td, i, txtValue;
      input = this.searchDataKey;
      
      filter = input.toUpperCase();
      table = document.getElementById("employeeDetails");
      //tr = document.getElementById("projectName");
      tr = table.getElementsByTagName("tr");
    
      // // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }

}