<!--Extended Table starts-->
<div class="row" *ngIf="displayPage">
  <div class="col-12">
    <div class="content-header">{{'Project Details' | translate}}</div>
  </div>
</div>

<section id="extended3" *ngIf="displayPage">
  <div class="row">

    
    <div class="col-12">
      <div class="card">
        <div class="card-header row">
         
        <div class="col">
          <div class="form-group " >
            <div class="col-12">
              <button type="button" class="btn btn-success mr-1 mb-1" [disabled]="isClientUser || isEmployeeUser" (click)="onAddProject()">{{'Add A Project' | translate}}</button>
            <button type="button" class="btn btn-warning mr-1 mb-1" (click)="toggleFilter()">{{'Filter' | translate}}</button>
            <div class="col-9 gws_form_group_adjustment">
            <div *ngIf="filter"> 
             
              <div class="form-group row">
                <label for="projectName" class="col-md-3 label-control">{{'Status' | translate}}</label>
                <div class="col-md-9">
                <div class="form-group">
                  <div *ngFor="let project of projectStatus" style="    display: inline-block;
                  margin-right: 10px;">
                    <label>
                      <input type="checkbox" id="projectStatusCheck" [value]="project.name" (change)="onCheckboxChange($event)" />
                      {{project.name}}
                    </label>
                  </div>
                  <!-- <input type="text" id="projectStatus" [(ngModel)]="projectStatus" class="form-control"
                    name="projectStatus" placeholder="{{'Status' | translate}}"> -->
         
                  </div>
                </div>
              </div>

              <!-- <div class="form-group row">
                <label class="col-md-3 label-control" for="striped-form-3">{{'Status' | translate}}</label>
                <div class="col-md-9">
                  <div class="form-group">
                    <ng-select [(ngModel)]="selectedProjectStatus" name="status" placeholder="{{'Status' | translate}}">
                      <ng-option *ngFor="let project of projectStatus" [value]="project.name">{{project.name}}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div> -->
    
             

              <!-- <div class="form-group row">
                <label class="col-md-3 label-control" for="striped-form-3">{{'Status' | translate}}</label>
                <div class="col-md-9">
                  <div class="form-group">
                    <ng-select [(ngModel)]="selectedProjectStatus" name="status" placeholder="{{'Status' | translate}}">
                      <ng-option *ngFor="let project of projectStatus" [value]="project.name">{{project.name}}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div> -->
             
            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-2">{{'From Date' | translate}}</label>
              <div class="col-md-9">
              <div class="form-group">
                <input class="form-control calendar" data-date-formats="['DD-MMM-YYYY']" (click)="fromDate.toggle()" placeholder="dd/mm/yyyy"
                    name="fromDate" [(ngModel)]="reportFromDate" ngbDatepicker #fromDate="ngbDatepicker" >
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control" for="striped-form-2">{{'To Date' | translate}}</label>
              <div class="col-md-9">
              <div class="form-group last">
                <input class="form-control calendar" (click)="toDate.toggle()" placeholder="dd/mm/yyyy"
                name="toDate" [(ngModel)]="reportToDate" ngbDatepicker #toDate="ngbDatepicker" >
                </div>
              </div>
            </div>

            <button type="button" class="btn btn-warning mr-1 mb-1"  (click)="searchProjectDataBasedOnFilter()">{{'Search Data' | translate}}</button>
            <button type="button" class="btn btn-light mr-1 mb-1"  (click)="clearFilter()">{{'Clear Filter' | translate}}</button>
          
          </div>
          </div>
        </div>
            <!-- <ngb-accordion [closeOthers]="true">
              <ngb-panel>
                <ng-template ngbPanelTitle>
                 
                    <button data-toggle="collapse" aria-controls="accordion1"  type="button" 
                    class="btn btn-warning mr-1 mb-1" aria-expanded="false"> Toggle </button> 
                    
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="form-group row">
                    <label class="col-md-3 label-control" for="striped-form-3">{{'Status' | translate}}</label>
                   
                   
                        <ng-select [(ngModel)]="selectedProjectStatus" name="status" placeholder="{{'Status' | translate}}">
                          <ng-option *ngFor="let project of projectStatus" [value]="project.name">{{project.name}}</ng-option>
                        </ng-select>
                      </div>
                   
                </ng-template>
              </ngb-panel>
            </ngb-accordion>    -->
          </div>
        </div>

        <div class="col-4 col-md-3">
        
          <div class="form-group form-inline">
            <input id="searchData" class="form-control ml-2" type="text" 
            name="searchData" [(ngModel)]="searchDataKey" (ngModelChange)="searchData()" placeholder="{{'Search with Project Name...' | translate}}" />
          </div>
        </div>
        </div>
        
       
        
        <div class="card-content">
          <div class="card-body table-responsive">
            <!-- <table id="projectDetails" class="table text-left m-0">-->
            <table id="projectDetails" class="table table-striped">
              <thead>
                <tr>
                  <!-- <th>#</th> -->
                  
                  <!-- <th>C. DATE</th> -->
                  
                  <!-- <th>EMPLOYEE</th> -->
                  <th>{{'PROJECT NAME' | translate}}</th>
                  <th class="sorting_desc">{{'PROJECT NUMBER' | translate}}</th>
                  <th>{{'COMPANY' | translate}}</th>
                  <th>{{'STATUS' | translate}}</th>
                  <th>{{'D. DATE' | translate}}</th>
                  <th *ngIf="isAdminUser">{{'ACTIONS' | translate}}</th>
                  
                 
                </tr>
              </thead>
              <tbody >
                <ng-container *ngFor="let projectDetail of projectDetails">
                <tr> 
                  <!-- <td>{{ projectDetail.pk_tb_project }}</td> -->
                
                  
                  <!-- <td>{{ projectDetail.employee_name }}</td> -->
                  <td id="projectName"><a href="javascript:;" class="success p-0" (click)="updateProjectDetails(projectDetail)">{{ projectDetail.project_name }}</a></td>
                  <td> <a href="javascript:;" class="success p-0" (click)="updateProjectDetails(projectDetail)">{{ projectDetail.project_number }}</a></td>
                  <td><a href="javascript:;" class="success p-0" (click)="updateProjectDetails(projectDetail)">{{ projectDetail.company_name }}</a></td>
                  <td><a href="javascript:;" class="success p-0" (click)="updateProjectDetails(projectDetail)">{{ projectDetail.status }}</a></td>
                  <td><a href="javascript:;" class="success p-0" (click)="updateProjectDetails(projectDetail)">{{ projectDetail.deliver_date_formatted }}</a></td>
                  <td class="text-truncate" *ngIf="isAdminUser">
                    <!-- <a href="javascript:;" class="success p-0" title="Edit">
                       <i class="ft-edit-2 font-medium-3 mr-2" (click)="updateProjectDetails(projectDetail)">    
                       </i>
                     </a>-->
                     
                     <a href="javascript:;" *ngIf="isAdminUser" class="danger p-0">
                       <i class="ft-x font-medium-3" (click)="deleteProjectDetailsByPK(projectDetail.pk_tb_project)"></i>
                     </a>
                 
                   </td>
                   
                
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Extended Table Ends-->

