<!--Extended Table starts-->
<div class="row" *ngIf="displayPage">
  <div class="col-12">
    <div class="content-header">{{'User Details' | translate}}</div>
  </div>
</div>
<section id="extended" *ngIf="displayPage">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <button type="button" class="btn btn-warning mr-1 mb-1" (click)="onAddUser()">{{'Add User' | translate}}</button>
          <div class="form-group form-inline" style="display: inline-block;">
          <input id="searchData" class="form-control ml-2" type="text" 
          name="searchData" [(ngModel)]="searchDataKey" (ngModelChange)="searchData()" placeholder="{{'Search with Employee Name...' | translate}}" />
        </div>
        </div>
        
        <div class="card-content">
          <div class="card-body table-responsive">
            
            <table id="employeeDetails" class="table table-striped">
              <thead>
                  <tr>
                    <!-- <th>ID</th> -->
                    <th>{{'Name' | translate}}</th>
                    <th>{{'Username' | translate}}</th>
                    <th>{{'Company Name' | translate}}</th>
                    <th>{{'Email' | translate}}</th>
                    <th>{{'Actions' | translate}}</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let userDetail of userDetails">
                  <!-- <td>{{ userDetail.pk_tb_user_details }}</td> -->
                  <td> <a href="javascript:;" class="success p-0" (click)="updateUserDetails(userDetail)">{{ userDetail.employee_name }}</a></td>
                  <td><a href="javascript:;" class="success p-0" (click)="updateUserDetails(userDetail)">{{ userDetail.username }}</a></td>
                  <td><a href="javascript:;" class="success p-0" (click)="updateUserDetails(userDetail)">{{ userDetail.company_name }}</a></td>
                  <td><a href="javascript:;" class="success p-0" (click)="updateUserDetails(userDetail)">{{ userDetail.email }}</a></td>
                  

                  <td class="text-truncate">
                   
                    <a href="javascript:;" class="success p-0">
                      <i class="ft-edit-2 font-medium-3 mr-2" (click)="updateUserDetails(userDetail)">
                       
                      </i>
                    </a>
                    <a href="javascript:;" class="danger p-0">
                      <i class="ft-x font-medium-3" (click)="deleteUserDetails(userDetail.pk_tb_user_details)"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Extended Table Ends-->
