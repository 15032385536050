import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import CompanyDetails from 'app/companyDetails/companyDetails';
import { HttpCommonMethods } from 'app/httpCommonMethods/httpCommonMethods';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-companyDetails',
  templateUrl: './companyDetails.component.html',
  styleUrls: ['./companyDetails.component.scss']
})

export class CompanyDetailsComponent {

  companyDetails: CompanyDetails[];
  //userDetailsInfo: UserDetails[] ;
  userrole : string;
  isAdminUser : boolean;
  isClientUser : boolean;
  isEmployeeUser : boolean;
  isAdminLiteUser : boolean;
  pk_tb_user_details : string;
  displayPage: boolean;
  searchDataKey : any; 

  constructor(private translate: TranslateService, private router: Router, private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private httpCommonMethods: HttpCommonMethods , private cdr:ChangeDetectorRef) {
      if(localStorage.length > 0){
        this.displayPage = true;
        console.log('Inside Company Details !')
      this.userrole = localStorage.getItem('role');
      this.pk_tb_user_details = localStorage.getItem('pk_tb_user_details');
      if(this.userrole === 'admin'){
          this.isAdminUser = true;
      } else if(this.userrole === 'admin-lite'){
        this.isAdminLiteUser = true;
      } else if(this.userrole === 'client') {
          this.isClientUser = true;
      } else {
        this.isEmployeeUser = true;
      }
      if(this.isClientUser || this.isEmployeeUser || this.isAdminLiteUser){
        alert('You are not authorized to view this page !')
        this.router.navigate(['/tables/projectDetails']);
      } else{
        this.getCompanyDetails();
      }
    } else {
      this.displayPage = false;
      alert('Either are not authorized to view this page / You have been logged out !')
      this.router.navigate(['/pages/login']);
    }
  }

  onNewCompany() {
    if(this.isAdminUser){
      this.router.navigate(['/forms/addCompany'], { relativeTo: this.route.parent });
    } else {
      alert("You are not authorized to Add New Company !")
    }
  }
  public getCompanyDetails() {
    if(this.isAdminUser){
      this.httpCommonMethods.getCompanyDetails()
        .subscribe(data => {
          if (data != null) {
            this.companyDetails = data;
            this.cdr.detectChanges();
          }
          else {
            alert('Error occured while getting Company details !');
          }
        });
    } else if(this.isClientUser){
      /*this.httpCommonMethods.getCompanyDetailsForClient(this.pk_tb_user_details)
      .subscribe(data => {
        if (data != null) {
          this.companyDetails = data;
        }
        else {
          alert('Error occured while getting Company details !');
        }
      });*/
      this.httpCommonMethods.getCompanyDetails()
        .subscribe(data => {
          if (data != null) {
            this.companyDetails = data;
          }
          else {
            alert('Error occured while getting Company details !');
          }
        });
    }
  }

  public updateCompanyDetails(companyDetail) {
    if(this.isAdminUser){
      let data = JSON.stringify(companyDetail);
      this.router.navigate(['/forms/updateCompany'], { relativeTo: this.route.parent, queryParams: { data } });
    } else {
      alert("You are not authorized to Update Company Details !")
    }
  }

  public deleteCompanyDetails(companyPK) {
    if (confirm('Are you sure you want to delete this Company?')) {
     
        if(this.isAdminUser){
        this.httpCommonMethods.deleteCompany(companyPK)
          .subscribe(data => {
            if (data != null) {
              alert('Details deleted Successfully !');
              location.reload();
            }
            else {
              alert('Error occured while deleting details !');
            }
          });
        } else {
          alert("You are not authorized to Delete Company Details !")
        }
  } 
  }

  public searchData() {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = this.searchDataKey;
    
    filter = input.toUpperCase();
    table = document.getElementById("companyDetails");
    tr = table.getElementsByTagName("tr");
  
    // // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
}